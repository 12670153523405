import Grid from "@mui/material/Grid2";
import QueryButton from "../common/querybutton";
import "./suggestion.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import CommentIcon from "@mui/icons-material/Comment";
import {
  getChoiceApi,
  getModifyClassdataApi,
} from "../../redux/action/UserAction";
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "../../redux/slices/userSlice";
import Loader from "../loader";
import { iconfun } from "../../utils/helperdata";
import AlertDialogSlide from "../sidebar/deleteModal";

const ModifySuggestion = ({
  onModifyClick,
  resdata,
  seteditGenerateQuery = () => {},
  conversationById,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  let idd = location?.state?.id;
  const {
    classDataLoading,
    modalClassesLoading,
    modifyClassData,
    optionsData,
  } = useSelector(userSelector);
  const [shouldFetch, setShouldFetch] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModal, setIsEditModel] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const handleOpen = (id) => {
    setOpen(true);
    setDeleteId(id);
  };

  const initialForm = {
    name: "",
    icon: "",
    is_deleted: true,
    is_option: false,
  };
  const [formData, setFormData] = useState(initialForm);
  const handleAddClick = (action, formData) => {
    setIsModalOpen(true);
    setIsEditModel(false);
    if (action == "edit") {
      setIsEditModel(true);
      setFormData(formData);
    }
  };
  const handleModifyQuery = () => {
    const oldquery =
      conversationById?.queries?.[0]?.query || conversationById?.query;
    const oldOptions =
      conversationById?.queries?.[0]?.options || conversationById?.options;
    seteditGenerateQuery(oldquery);
    navigate("/", {
      state: {
        oldq: oldquery,
        isModifyQuery: true,
        options: oldOptions,
      },
    });
  };

  const optionData = resdata?.data?.queries?.[0]?.options;
  const formik = useFormik({
    initialValues: {
      subject: "",
    },
    validateOnChange: true,

    onSubmit: (values) => {
      onModifyClick(values);
    },
  });

  useEffect(() => {
    dispatch(getChoiceApi());
  }, []);

  // useEffect(() => {
  //   if (optionData && typeof optionData === "object") {
  //     Object.keys(optionData).forEach((key) => {
  //       let updatedKey = key;
  //       if (key === "Output") {
  //         updatedKey = "output";
  //       }
  //       formik.setFieldValue(updatedKey, optionData[key]);
  //     });
  //   }
  // }, [resdata]);

  useEffect(() => {
    const fetchData = async () => {
      if (shouldFetch) {
        dispatch(getModifyClassdataApi());
        setShouldFetch(false);
      }
    };
    fetchData();
  }, [shouldFetch]);

  useEffect(() => {
    if (optionsData === null || optionsData === "") {
      const emptyvalues = {
        subject: "",
        language: "",
        output: "",
        length: "",
        audience: "",
      };
      formik.resetForm({ values: emptyvalues });
    } else {
      const emptyvalues = {
        subject: "",
        language: "",
        output: "",
        length: "",
        audience: "",
      };
      formik.resetForm({ values: emptyvalues });

      formik.resetForm({
        values: optionsData,
      });
    }
  }, [idd, conversationById]);

  return (
    <>
      {open && (
        <AlertDialogSlide
          open={open}
          setOpen={setOpen}
          setShouldFetch={setShouldFetch}
          deleteId={deleteId}
        />
      )}

      {modalClassesLoading ? (
        <Loader />
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <Grid
            className="container"
            container
            alignItems="stretch"
            spacing={2}
          >
            {classDataLoading ? (
              <Loader />
            ) : (
              modifyClassData?.map((data) => {
                return (
                  <>
                    <Grid
                      key={data?.name}
                      size={{ lg: 3, md: 3, xs: 3 }}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ width: "80%" }}>
                        <div className="suggestionContainer">
                          <p
                            key={data?.name}
                            style={{ margin: 0, width: "50%" }}
                          >
                            {data?.name}
                          </p>
                          {data?.icon ? (
                            <img
                              className="img-icon"
                              src={iconfun(data?.icon)}
                              alt="img"
                            />
                          ) : (
                            <CommentIcon
                              sx={{
                                fontSize: "16px",
                                width: "50px",
                                color: "green",
                              }}
                            />
                          )}
                        </div>
                      </div>
                      {/* <div className="add-subject">
                        <div>
                          {data?.is_deleted === true ? (
                            <ModeEditOutlineIcon
                              sx={{
                                fontSize: "18px",
                                color: "#4b5669",
                                cursor: "pointer",
                              }}
                              onClick={() => handleAddClick("edit", data)}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        <div>
                          {data?.is_deleted === true ? (
                            <DeleteOutlineIcon
                              sx={{
                                fontSize: "18px",
                                color: "#4b5669",
                                cursor: "pointer",
                              }}
                              onClick={() => handleOpen(data?.id)}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div> */}
                    </Grid>

                    <Grid size={{ lg: 9, md: 9, xs: 9 }}>
                      <input
                        // ref={inputRef}
                        value={formik?.values[data?.name]}
                        onChange={formik.handleChange}
                        name={data?.name}
                        // disabled={data?.is_option ? true : false}
                        placeholder={"Fill in"}
                        type="text"
                        className="suggestioninput"
                      />
                    </Grid>
                  </>
                );
              })
            )}

            <div className="suggestioncontentbtn">
              <QueryButton
                type={"button"}
                btntext={"Modify Response"}
                btnclick={() => formik.handleSubmit()}
              />
              <QueryButton
                btnclick={() => handleModifyQuery()}
                btntext={"Modify Query"}
              />
            </div>
          </Grid>
        </form>
      )}
    </>
  );
};

export default ModifySuggestion;
