import { BASE_URL } from "../../constants";
import {
  addClassesReduc,
  allConversationReduc,
  classDataReduc,
  conversationByIdReduc,
  deleteClassesReduc,
  editClassesReduc,
  fetchAllData,
  getChoiceReduc,
  modifyResponseReduc,
  queryResponseReduc,
} from "../slices/userSlice";
import { api } from "../../utils/api";
import { FailureToast, SuccessToast } from "../../utils/commonunctions";

export function fetchAllDataAPI() {
  return async (dispatch) => {
    await dispatch(fetchAllData({ recentLoading: true }));
    await api
      .get(`${BASE_URL}/conversations/recent/`)
      .then((res) => {
        if (res.status === 200) {
          dispatch(
            fetchAllData({
              recentLoading: false,
              fetchConversation: res?.data?.results,
            })
          );
        }
      })
      .catch((e) => {
        dispatch(
          fetchAllData({
            recentLoading: false,
          })
        );
      });
  };
}

export function fetchAllConversationAPI() {
  return async (dispatch) => {
    await dispatch(allConversationReduc({ convoLoading: true }));
    await api
      .get(`${BASE_URL}/conversations/`)
      .then((res) => {
        if (res.status === 200) {
          dispatch(
            allConversationReduc({
              data: res?.data?.results,
              convoLoading: false,
            })
          );
        }
      })
      .catch((e) => {
        dispatch(allConversationReduc({ convoLoading: false }));
      });
  };
}

export function conversationByIdAPI(id, isHistory, setIsLoading) {
  return async (dispatch) => {
    await dispatch(conversationByIdReduc({ conversationLoading: true }));
    setIsLoading(true);
    await api
      .get(
        `${
          isHistory
            ? `${BASE_URL}/conversations/${id}/`
            : `${BASE_URL}/submit_query/${id}/`
        }`
      )
      .then((res) => {
        if (res.status === 200) {
          setIsLoading(false);
          isHistory
            ? dispatch(
                conversationByIdReduc({
                  optionsData: res?.data?.queries?.[0]?.options,
                  data: res?.data,
                  conversationLoading: false,
                })
              )
            : dispatch(
                conversationByIdReduc({
                  optionsData: res?.data?.options,
                  data: res?.data,
                  conversationLoading: false,
                })
              );
        }
      })
      .catch((e) => {
        setIsLoading(false);
        dispatch(conversationByIdReduc({ conversationLoading: false }));
      });
  };
}

export function getClassdataApi() {
  return async (dispatch) => {
    await dispatch(classDataReduc({ classDataLoading: true }));
    await api
      .get(`${BASE_URL}/categories/`)
      .then((res) => {
        if (res.status === 200) {
          dispatch(
            classDataReduc({
              classDataLoading: false,
              data: res?.data,
            })
          );
        }
      })
      .catch((e) => {
        dispatch(classDataReduc({ classDataLoading: false }));
      });
  };
}

export function createClassesApi(
  data,
  setShouldFetch,
  setIsModalOpen,
  setFormData,
  initialForm
) {
  return async (dispatch) => {
    await dispatch(addClassesReduc({ modalClassesLoading: true }));
    await api
      .post(`${BASE_URL}/categories/`, data)
      .then((res) => {
        if (res.status === 200) {
          FailureToast(res?.data?.message);
          dispatch(addClassesReduc({ modalClassesLoading: false }));
        }
        if (res.status === 201) {
          SuccessToast("Successfully Added");
          setShouldFetch(true);
          setIsModalOpen(false);
          setFormData(initialForm);
          dispatch(
            addClassesReduc({
              modalClassesLoading: false,
            })
          );
        }
      })
      .catch((e) => {
        dispatch(addClassesReduc({ modalClassesLoading: false }));
        FailureToast(e?.response?.data?.message);
        setIsModalOpen(false);
      });
  };
}

export function editClassesApi(
  setShouldFetch,
  setIsModalOpen,
  setFormData,
  initialForm,
  newData,
  body
) {
  return async (dispatch) => {
    await dispatch(editClassesReduc({ modalClassesLoading: true }));
    await api
      .put(`${BASE_URL}/categories/${newData.id}/`, body)
      .then((res) => {
        if (res.status === 200) {
          SuccessToast("Successfully Edited");
          dispatch(
            editClassesReduc({
              modalClassesLoading: false,
            })
          );
          setShouldFetch(true);
          setIsModalOpen(false);
          setFormData(initialForm);
        }
      })
      .catch((err) => {
        dispatch(editClassesReduc({ modalClassesLoading: false }));
        FailureToast(err?.response?.data?.message);
        setIsModalOpen(false);
      });
  };
}
export function deleteClassesApi(setOpen, setShouldFetch, deleteId) {
  return async (dispatch) => {
    await dispatch(deleteClassesReduc({ modalClassesLoading: true }));
    await api
      .delete(`${BASE_URL}/categories/${deleteId}/`)
      .then((res) => {
        if (res.status === 200) {
          setOpen(false);
          setShouldFetch(true);
          SuccessToast("Successfully Deleted");
          dispatch(
            deleteClassesReduc({
              modalClassesLoading: false,
            })
          );
        }
      })
      .catch((e) => {
        dispatch(deleteClassesReduc({ modalClassesLoading: false }));
      });
  };
}

export function modifyResponseApi(
  id,
  data,
  setResponse,
  setIsLoading,
  formatResponseText
) {
  return async (dispatch) => {
    setIsLoading(true);
    await dispatch(modifyResponseReduc({ modifyLoading: true }));
    await api
      .post(`${BASE_URL}/modify_response/${id}/`, data)
      .then((res) => {
        if (res?.status === 200) {
          setIsLoading(false);
          setResponse(formatResponseText(res?.data?.modified_response));
          dispatch(
            modifyResponseReduc({
              modifyLoading: false,
            })
          );
        }
      })
      .catch((e) => {
        setIsLoading(false);
        dispatch(modifyResponseReduc({ modifyLoading: false }));
      });
  };
}

export function getChoiceApi() {
  return async (dispatch) => {
    await api
      .get(`${BASE_URL}/choices/`)
      .then((res) => {
        dispatch(
          getChoiceReduc({
            getChoices: res?.data,
          })
        );
      })
      .catch((err) => {
        return err;
      });
  };
}

export function addChoiceApi(setEditModal, payload, setCategoryValue) {
  return async (dispatch) => {
    await api
      .post(`${BASE_URL}/choices/`, payload)
      .then((res) => {
        if (res?.status === 201 || res?.status === 200) {
          dispatch(getChoiceApi());
          SuccessToast("Choice Added Successfully");
          setTimeout(() => {
            setEditModal(false);
            setCategoryValue("")
          }, 1200);
        }
      })
      .catch((error) => {
        const errorMessage =
          error?.response?.data?.error === "Choice not found"
            ? "Default Choice cannot be update"
            : error?.response?.data?.value?.[0] ?? "Error occur while editing";
        FailureToast(errorMessage);
      });
  };
}

export function editChoiceApi(setEditModal, id, dataId, payload, setCategoryValue) {
  return async (dispatch) => {
    await api
      .put(`${BASE_URL}/choices/${id}/${dataId}/`, payload)
      .then((res) => {
        if (res?.status === 200) {
          SuccessToast("Choice Edited Successfully");
          dispatch(getChoiceApi());
          setTimeout(() => {
            setEditModal(false);
            setCategoryValue("")
          }, 1200);
        }
      })
      .catch((error) => {
        const errorMessage =
          error?.response?.data?.error === "Choice not found"
            ? "Default Choice cannot be update"
            : error?.response?.data?.value?.[0] ?? "Error occur while editing";
        FailureToast(errorMessage);
      });
  };
}

export function deleteChoiceApi(setEditModal, id, dataId, setCategoryValue) {
  return async (dispatch) => {
    await api
      .delete(`${BASE_URL}/choices/${id}/${dataId}/`)
      .then((res) => {
        if (res?.status === 200) {
          SuccessToast("Choice Deleted Successfully");
          dispatch(getChoiceApi());
          setTimeout(() => {
            setEditModal(false);
            setCategoryValue("")
          }, 1200);
        }
      })
      .catch((error) => {
        const errorMessage =
          error?.response?.data?.error === "Choice not found"
            ? "Default Choice cannot be update"
            : error?.response?.data?.value?.[0] ?? "Error occur while editing";
        FailureToast(errorMessage);
      });
  };
}

export function submitQueryApi(query, setIsGenerating, navigate) {
  return async (dispatch) => {
    setIsGenerating(true);
    await api
      .post(`${BASE_URL}/submit_query/`, query)
      .then((res) => {
        if (res?.status === 201) {
          setIsGenerating(false);
          dispatch(fetchAllConversationAPI());
          navigate(`/response `, {
            state: { id: res?.data?.id, showres: true, newQuery: true },
          });
          dispatch(
            queryResponseReduc({
              queryResponse: res?.data,
            })
          );
        }
      })
      .catch((err) => {
        setIsGenerating(false);
      });
  };
}

export function saveQueryApi(data) {
  return async (dispatch) => {
    await api
      .post(`${BASE_URL}/queries/`, data)
      .then((res) => {
        if (res?.status === 200) {
          // SuccessToast()
        }
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };
}

export function ChangePasswordApi(data, navigate) {
  return async (dispatch) => {
    await api
      .post(`${BASE_URL}/change_password/`, data)
      .then((res) => {
        if (res?.status === 200) {
          SuccessToast(res?.data?.message);
          setTimeout(() => {
            sessionStorage.clear();
            navigate("/");
          }, 1000);
        }
      })
      .catch((err) => {
        FailureToast(err?.response?.data?.error);
      });
  };
}

export function deleteConversationReduc(id, navigate) {
  return async (dispatch) => {
    await api
      .delete(`${BASE_URL}/conversations/${id}/`)
      .then((res) => {
        if (res?.status === 200 || res?.status === 204) {
          SuccessToast("Deleted Successfully");
          dispatch(fetchAllConversationAPI());
          dispatch(fetchAllDataAPI({ recentLoading: true }));
          navigate("/");
        } else {
          FailureToast("Deletion Failed");
          navigate("/");
        }
      })
      .catch((err) => {
        FailureToast("Deletion Failed");
      });
  };
}

export function clearAllConversationApi(setOpen) {
  return async (dispatch) => {
    await api
      .patch(`${BASE_URL}/clear_conversation/`, null)
      .then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          setOpen(false);
          dispatch(fetchAllDataAPI());
          dispatch(fetchAllConversationAPI());
          SuccessToast("All Conversation deleted");
        }
      })
      .catch((err) => {
        setOpen(false);
        FailureToast(err?.response?.data?.message);
      });
  };
}

export function getModifyClassdataApi() {
  return async (dispatch) => {
    await dispatch(classDataReduc({ classDataLoading: true }));
    await api
      .get(`${BASE_URL}/modify_categories/`)
      .then((res) => {
        if (res.status === 200) {
          dispatch(
            classDataReduc({
              classDataLoading: false,
              modifyClassData: res?.data,
            })
          );
        }
      })
      .catch((e) => {
        dispatch(classDataReduc({ classDataLoading: false }));
      });
  };
}
