import { useEffect, useState } from "react";
import "./suggestionDropdown.css";
import { useSelector } from "react-redux";
import { userSelector } from "../../../redux/slices/userSlice";


const SuggestionDropdown = ({
  customstyle,
  onChange,
  isDisable,
  id,
  isEdit,
  finaldata,
  formikvalues,
}) => {
  const [choice, setChoice] = useState([]);
  const { getChoices } = useSelector(userSelector);
  const dropDown = () => {
    const filterData = getChoices.filter(
      (item) => item.choice_type.toLowerCase() === id?.toLowerCase()
    );
    setChoice(filterData);
  };

  const finalData = getChoices.map((item) => item.value);

  useEffect(() => {
    dropDown();
  }, [getChoices]);

  return isEdit ? (
   
      <select
        className="suggestionselectcontainer"
        name={id}
        style={customstyle}
        onChange={onChange}
        disabled={isDisable}
      >
        <option value={""}>Choose</option>
        {finaldata?.length > 0 &&
          finaldata?.map((option) => (
            <option
              key={`${option.id}-${option.value}`}
              id={id}
              value={option.value}
              data-id={option.id}
            >
              {option.value}
            </option>
          ))}
      </select>
    
  ) : (
    
      <select
        className="suggestionselectcontainer"
        name={id}
        style={customstyle}
        onChange={onChange}
        disabled={isDisable}
        value={finalData.includes(formikvalues[id]) ? formikvalues[id] : ""}
      >
        <option value={""}>Choose</option>
        {choice?.length > 0 &&
          choice?.map((option) => (
            <option
              key={`${option.id}-${option.value}`}
              id={id}
              value={option.value}
              data-id={option.id}
            >
              {option.value}
            </option>
          ))}
      </select>
  );

};

export default SuggestionDropdown;
