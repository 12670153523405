import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "./editsuggestion.css";
import { useDispatch } from "react-redux";
import {
  addChoiceApi,
  deleteChoiceApi,
  editChoiceApi,
} from "../../../redux/action/UserAction";
import { FailureToast } from "../../../utils/commonunctions";
import QueryButton from "../../../components/common/querybutton";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteIcon from "@mui/icons-material/Delete";
import SuggestionDropdown from "../../../components/common/suggestionDropdown";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
};

const EditSuggestionModal = ({ editModal, setEditModal, saveCatagoryID }) => {
  const { categoryId, finaldat, id, isEdit } = saveCatagoryID;
  const dispatch = useDispatch();
  const [dataId, setDataId] = useState();
  const [categoryValue, setCategoryValue] = useState("");

  const handleAdd = async () => {
    const payload = {
      category: categoryId,
      choice_type: id,
      value: categoryValue,
    };

    if (payload.choice_type === "length" && parseInt(payload.value) < 10) {
      FailureToast("Length Require Minimum 10");
    } else {
      dispatch(addChoiceApi(setEditModal, payload, setCategoryValue));
    }
  };



  const handleEdit = async () => {
    const payload = {
      choice_type: id,
      value: categoryValue,
    };

    if (payload.choice_type === "length" && parseInt(payload.value) < 10) {
      FailureToast("Length Require Minimum 10");
    } else {
      dispatch(editChoiceApi(setEditModal, id, dataId, payload, setCategoryValue));
    }
  };

  const handleDelete = async () => {
    dispatch(deleteChoiceApi(setEditModal, id, dataId, setCategoryValue));
  };

  const addCategory = async () => {
    setEditModal(false)
    setCategoryValue()
    setDataId()
  };

  const choiceOption = (e) => {
    const selectedValue = e?.target?.value;

    setDataId(
      e?.target?.options[e?.target?.selectedIndex].getAttribute("data-id")
    );

    setCategoryValue(selectedValue);
  };

  return (
    <div>
      <Modal
        open={editModal}
        aria-labelledby="modal-modal-name"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <>
            <div className="maincontenthead">Edit Suggestion</div>
            <div className="editsuggestioncontainer">
              <div className="editsuggestionoption">
                <SuggestionDropdown
                  isEdit={isEdit}
                  customstyle={{ width: "30%" }}
                  finaldata={finaldat}
                  value={categoryValue}
                  onChange={choiceOption}
                />
              </div>
              <div className="editsuggestionfillsection">
                <input
                  className="suggestioninput"
                  placeholder="Edit Category"
                  value={categoryValue}
                  onChange={(e) => setCategoryValue(e?.target?.value)}
                  type={id === "length" ? "number" : "text"}
                />
                <div className="edit-icons">
                  <div
                    onClick={dataId ? undefined : () => handleAdd()}
                    style={{
                      cursor: dataId ? "not-allowed" : "pointer",
                    }}
                  >
                    <AddCircleOutlineIcon
                      style={{
                        color: dataId ? "#a9a9a9" : "#35A600",
                        fontSize: "25px",
                      }}
                    />
                  </div>
                  <div
                    onClick={dataId ? handleEdit : undefined}
                    style={{ cursor: dataId ? "pointer" : "not-allowed" }}
                    aria-disabled={!dataId}
                  >
                    <EditNoteIcon
                      style={{
                        color: dataId ? "#F29D38" : "#a9a9a9",
                        fontSize: "25px",
                      }}
                    />
                  </div>
                  <div
                    onClick={dataId ? handleDelete : undefined}
                    style={{ cursor: dataId ? "pointer" : "not-allowed" }}
                    aria-disabled={!dataId}
                  >
                    <DeleteIcon
                      style={{
                        color: dataId ? "#F70000" : "a9a9a9",
                        fontSize: "25px",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "3%",
              }}
            >
              <QueryButton btntext={"Cancel"} btnclick={addCategory} />
            </div>
          </>
        </Box>
      </Modal>
    </div>
  );
};

export default EditSuggestionModal;
